<template>
  <div class="column has-text-centered">
<!--
    <div class="title is-4" v-html="pageData.fields.title"></div>
    <div class="title is-5" v-html="pageData.fields.subtitle"></div>
-->
<!--    <div class="content appbuttontext" v-html="pageData.fields.htmlText"></div>-->
    <rich-text :document="pageData.fields.richText" class="appbuttontext" :background="background"></rich-text>
    <template  v-if="validURL">
      <a :class="buttonClass"
          :href="pageData.fields.url"
          target="untangl_docs"
      >
        <span>{{ pageData.fields.buttonTitle }}</span>
        <span class="buttonicon">
          <font-awesome-icon  :icon="['fal', 'external-link-alt']" />
        </span>
      </a>
    </template>
    <template v-else>
      <router-link tag="button"
                   :to="pageData.fields.to"
                   :class="buttonClass">
        {{ pageData.fields.buttonTitle }}
      </router-link>
    </template>
    <div class="has-space-below"></div>
  </div>
</template>

<script>
  import RichText from '@/components/RichText.vue'

  export default {
    name: 'ButtonPanel',
    props: {
      pageData: Object,
      rootPageData: Object,
      background: String
    },
    components: {
      RichText
    },
    computed: {
      buttonClass() {
        //console.log("[buttonClass] this.rootPageData:", JSON.stringify(this.rootPageData, '', 2));
        let c = "button is-outlined is-radiusless is-uppercase ";
        //console.log("[buttonClass] this.background:", this.background);
        if (this.rootPageData.fields.background !== 'has-background-white') {
          c = c + ' is-inverted is-danger ';
        }
        return c;
      },
      // We use an <a> tag and format it as a button to get a link - see template
      //Use the URL rather than the "To" state. todo a bit more validation that this is a valid URL
      validURL() {
        let validity = ((typeof this.pageData.fields.url !== 'undefined') &&
          this.pageData.fields.url !== null &&
          this.pageData.fields.url !== "" );
        return validity;
      }
    }
  }
</script>



<!--DOM content created with v-html are not affected by scoped styles, but you can still style them using deep selectors.-->
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  //the first three to correct a bug in Bulma version 8
  @import "~bulma/sass/utilities/functions";
  @import "~bulma/sass/utilities/initial-variables";
  @import "~bulma/sass/utilities/derived-variables";
  @import "~bulma/sass/utilities/mixins";
  @import "@/scss/_bulmaOverridden";

  div.appbuttontext {
    height: 100%;
  }

</style>
